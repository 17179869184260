import './Title.css';

const Title = () => {
    return (
        <div>
        <p class="head">Olof.Rollsby.Soy!</p>
        <div className="fan-message">
          <p>Olof Rollsby number one supper fan site!!!</p>
          <p>H8rs gunna H8!!!</p>
        </div>
        <p>
          <audio controls autoplay="autoplay">
          <source src="audio/Mr Beast Outro Sound Effect.mp3" type="audio/mpeg"/>
          </audio>
        </p>
        </div>
    )
}

export default Title;